<template>
  <div>
    <div class="header">
<!--      <img class="za-logo" :src="zaLogo">-->
<!--      <span class="divider-line" />-->
      <img class="logo" :src="logo">
      <img class="menu" :src="menuimg" @click="menuClick">
    </div>
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper">
        <!-- :url="menu.link" -->
        <van-cell v-for="(menu, index) in menus" :key="index" class="cell" is-link :title="menu.title" @click="routerPage(menu.link)">
          <!-- <router-link :to="menu.link" /> -->
        </van-cell>
      </div>
    </van-overlay>
  </div>
</template>

<script>
// import zaLogo from '@/assets/home/ZA-logo.png'
import logo from '@/assets/home/home-logo.png'
import menuimg from '@/assets/home/home-menu.png'
export default {
  data() {
    return {
      show: false,
      menus: [
        { title: '众企安链', link: '/' },
        { title: '新闻动态', link: '/dynamic' },
        { title: '关于我们', link: '/about' }
      ],
      // zaLogo,
      logo,
      menuimg,
    }
  },
  methods: {
    menuClick() {
      this.show = !this.show
    },
    routerPage(link) {
      if (location.pathname === link) return
      this.$router.push(link)
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;;
  height: 50px;
  z-index: 999;
  .za-logo {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 100px;
    height: 30px;
    border-color: transparent;
    background-color: transparent;
  }
  .divider-line {
    position: absolute;
    left: 116px;
    top: 16px;
    width: 1px;
    height: 17px;
    background-color: rgba($color: #fff, $alpha: .5);
  }
  .logo {
    position: absolute;
    left: 120px;
    top: 10px;
    width: 100px;
    height: 30px;
    border-color: transparent;
    background-color: transparent;
  }
  .menu {
    position: absolute;
    right: 20px;
    top: 15px;
    width: 16px;
    height: 13px;
    border-color: transparent;
    background-color: transparent;
    cursor: pointer;
  }
}
.wrapper {
  position: absolute;
  left: 20px;
  right: 20px;
  top: 50px;
  border-radius: 5px;
}
.cell {
  text-align: left;
  &:first-child {
    border-top-left-radius: 5px; /* no */
    border-top-right-radius: 5px; /* no */
  }
  &:last-child {
    border-bottom-left-radius: 5px; /* no */
    border-bottom-right-radius: 5px; /* no */
  }
}
</style>
