<template>
  <div class="footer">
    <!-- <img class="qrcode">
    <div class="attention-title">关注众企安链公众号</div> -->
    <van-button round icon="phone" class="phone-btn">021-6027 2444</van-button>
    <div class="intro-title">业务咨询与市场合作</div>
    <div class="intro-title">anlink_so@zqalink.com</div>
    <van-divider hairline class="divider">
      <img :src="logo">
    </van-divider>
    <div class="intro-content-first">
      上海聚啊信息技术有限公司
    </div>
    <div class="intro-content-last" @click="icpLink">
      沪ICP备20009652号-1
    </div>
    <a id="icp-link" style="display: none;" href="http://www.beian.miit.gov.cn/" target="__blank" />
  </div>
</template>

<script>
import logo from '@/assets/home/home-logo.png'
export default {
  data() {
    return {
      show: false,
      logo,
      menus: [
        { title: '众企安链', link: '/' },
        { title: '新闻动态', link: '/dynamic' },
        { title: '关于我们', link: '/about' }
      ]
    }
  },
  methods: {
    icpLink() {
      const a = document.getElementById('icp-link')
      a.click()
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  background: url("~@/assets/home/footer-bg.png") no-repeat;
  background-size: 100% 514px;
  width: 100%;
  // height: 514px;
  height: 330px;
}
.qrcode {
  background: url("~@/assets/home/qrcode.png") no-repeat;
  background-size: 108px 108px;
  width: 108px;
  height: 108px;
  margin-top: 88px;
}
.attention-title {
  color: white;
  font-size: 14px;
}
.phone-btn {
  margin-top: 52px;
  width: 280px;
  height: 45px;
  background: #00C6C0;
  color: white;
  font-size: 18px; /** no */
  border-color: transparent;
}
.intro-title {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px; /** no */
  margin-top: 15px;
}
.divider {
  color: rgba(255, 255, 255, 0.6);
  border-color: rgba(255, 255, 255, 0.6);
  padding: 0 16px;
  margin-top: 45px;
  img {
    width: 100px;
    height: 30px;
  }
}
.intro-content-first {
  color: rgba(255, 255, 255, 0.5);
  font-size: 11px; /** no */
  margin-top: 29px;
}
.intro-content-last {
  color: rgba(255, 255, 255, 0.5);
  font-size: 11px; /** no */
  margin-top: 5px;
  // margin-top: 29px;
}
// .intro-content {
//   color: rgba(255, 255, 255, 0.5);
//   font-size: 11px; /** no */
//   margin-top: 29px;
//   width: 198px; /** no */
//   height: 41px; /** no */
// }
</style>
