<template>
  <div class="container">
    <template id="basic-head">
      <van-swipe class="swipe" :autoplay="autoTime">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img class="image" :src="image">
        </van-swipe-item>
      </van-swipe>
      <!-- <van-button class="use-btn" round type="info" @click="useAction">去 试 用</van-button> -->
    </template>

    <template id="contact">
      <!-- style="margin-bottom: -25px" -->
      <entertaining-diversions />
      <position />
      <advantage />
      <scenario />
      <news />
      <contact-us style="margin-bottom: -10%" />
    </template>

  </div>
</template>

<script>
import banner1 from '@/assets/home/banner1.png'
import banner2 from '@/assets/home/banner2.png'
import banner3 from '@/assets/home/banner3.png'
import ContactUs from './components/ContactUs.vue'
import EntertainingDiversions from './components/EntertainingDiversions.vue'
import position from './components/position.vue'
import advantage from './components/advantage.vue'
import scenario from './components/scenario.vue'
import news from './components/news.vue'

export default {
  components: {
    ContactUs,
    EntertainingDiversions,
    position,
    advantage,
    scenario,
    news,
  },
  data() {
    return {
      autoTime: 5000,
      images: [
        banner1,
        banner2,
        banner3
      ]

    }
  },
  methods: {
    useAction() {
      console.log('去试用')
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  .swipe{
    height: 464px;
  }
}
.image {
  background: no-repeat;
  background-size: 100% 464px;
  width: 100%;
  height: 464px;
}
.use-btn {
  position: absolute;
  left: calc(50% - 75px);
  top: 373px;
  // bottom: 50px;
  width: 150px;
  height: 40px;
  background-color: #00C6C0;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

</style>
