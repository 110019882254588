<template>
  <div>
    <div class="header">
      <span class="title">最新动态</span>
    </div>
    <!-- <img class="header"> -->
    <span class="content-title">新闻资讯</span>
    <div class="news-container-content">
      <div v-for="(item, index) in totalList" :key="index" class="news-container-content-new" @click="clickMethod(item.src)">
        <div class="new-left">
          <img :src="getImg(item.img)">
        </div>
        <div class="new-right">
          <div class="new-right-head">{{ item.title }}</div>
          <div class="new-right-content">{{ item.content }}</div>
          <div class="new-right-bottom">来源：{{ item.source }}</div>
        </div>
      </div>
    </div>
    <!-- <div class="news-container-more">查看更多</div> -->
    <a id="linkId" :href="linkUrl" target="_blank" style="display: none" />
  </div>
</template>

<script>
import mixin from '@/utils/dynamicMixin.js'

export default {
  mixins: [mixin],
  data() {
    return {
      linkUrl: ''
    }
  },
  methods: {
    getImg(imgName) {
      if (!imgName) return
      return require('@/assets/news/' + imgName)
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  background: url("~@/assets/dynamic/head-bg.png") no-repeat;
  background-size: 100% 160px;
  width: 100%;
  height: 160px;
  .title {
    position: absolute;
    top: 75px;
    left: 0;
    width: 100%;
    font-size: 23px;
    color: white;
    font-weight: bold;
  }
}
.content-title {
  width: 100%;
  height: 100px;
  line-height: 100px;
  font-size: 23px;
  color: #2E3A48;
}
.news-container-content{
    margin-top: 5px;
    padding: 0 15px;
    margin-bottom: 50px;
    .news-container-content-new{
      width: 100%;
      height: 90px;
      position: relative;
      margin-top: 20px;
      &:first-child{
        margin-top: 0;
      }
      .new-left {
        width: 113px;
        height: 90px;
        overflow: hidden;
        // position: relative;
        img {
          // position: absolute;
          // margin: auto;
          // left: 0;
          // right: 0;
          // top: 0;
          // bottom: 0;
          width: 100%;
          background-size: cover;
        }
      }
      .new-right{
        position: absolute;
        top: 0;
        left: 128px;
        text-align: left;
        width: 216px;
        height: 90px;
        .new-right-head{
          font-size: 15px;
          font-family:PingFangSC-Medium,PingFang SC;
          font-weight:500;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .new-right-content{
          font-size:12px;
          font-weight:400;
          color:rgba(56,70,86,1);
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          margin-top: 5px;
        }
        .new-right-bottom{
          font-size:12px;
          font-weight:400;
          color:rgba(151,163,180,1);
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
  .news-container-more{
    font-size:15px;
    font-weight:500;
    color:rgba(0,198,192,1);
    text-align: center;
    margin-top: 25px;
    margin-bottom: 27px;
  }
</style>
