import Vue from 'vue'
import 'vant/lib/icon/local.css'
import {
  ActionSheet,
  Button,
  Cell,
  CellGroup,
  Field,
  Icon,
  Lazyload,
  Loading,
  Toast,
  Popup,
  Swipe,
  SwipeItem,
  Overlay,
  Divider,
  Form,
  Notify,
  Tab,
  Tabs,
  Collapse,
  CollapseItem
} from 'vant'

Vue.use(ActionSheet)
  .use(Button)
  .use(Cell)
  .use(CellGroup)
  .use(Field)
  .use(Icon)
  .use(Lazyload)
  .use(Loading)
  .use(Toast)
  .use(Popup)
  .use(Swipe)
  .use(SwipeItem)
  .use(Overlay)
  .use(Divider)
  .use(Form)
  .use(Notify)
  .use(Tab)
  .use(Tabs)
  .use(Collapse)
  .use(CollapseItem)

Vue.directive('rules', {
  update: function() {
    const message = document.createElement('div')
    const valueEL = arguments[0].getElementsByClassName('van-cell__value')[0]
    const val = arguments[0].getElementsByTagName('input')[0].value
    const errors = arguments[0].getElementsByClassName(
      'van-field__error-message'
    )
    for (let i = 0; i < errors.length; i++) {
      valueEL.removeChild(errors[i])
    }
    message.setAttribute('class', 'van-field__error-message')
    arguments[1].value.map(rule => {
      if (rule.required && val === '') {
        message.innerText = rule.message
        return false
      }
      if (rule.pattern && !new RegExp(rule.pattern).test(val)) {
        message.innerText = rule.message
        return false
      }
    })
    if (message.innerText) {
      valueEL.appendChild(message)
    }
  }
})
