<template>
  <div class="news-container">
    <div class="news-container-head">新闻资讯</div>
    <div class="news-container-content">
      <div v-for="(item, index) in list" :key="index" class="news-container-content-new" @click="clickMethod(item.src)">
        <img class="new-left" :src="getImg(item.img)">
        <div class="new-right">
          <div class="new-right-head">{{ item.title }}</div>
          <div class="new-right-content">{{ item.content }}</div>
          <div class="new-right-bottom">来源：{{ item.source }}</div>
        </div>
      </div>
    </div>
    <div class="news-container-more" @click="lookMore">查看更多</div>
    <a id="linkId" target="_blank" style="display: none" />
  </div>
</template>
<script>
import mixin from '@/utils/dynamicMixin.js'

export default {
  mixins: [mixin],
  data() {
    return {
      linkUrl: ''
    }
  },
  computed: {
    list() {
      if (this.totalList.length > 3) {
        return this.totalList.slice(0, 3)
      }
      return this.totalList
    }
  },
  methods: {
    lookMore() {
      this.$router.push('/dynamic')
    },
    getImg(imgName) {
      return require('@/assets/news/' + imgName)
    }
  }
}
</script>

<style lang="scss" scoped>
.news-container{
  width: 100%;
  height: 482px;
  background: #E8F0FF;
  .news-container-head{
    font-size:23px;
    font-family:PingFangSC-Light,PingFang SC;
    font-weight:300;
    color:rgba(46,58,72,1);
    text-align: center;
    padding-top: 30px;
  }
  .news-container-content {
    margin-top: 5px;
    padding: 0 15px;
    .news-container-content-new{
      width: 100%;
      height: 90px;
      position: relative;
      margin-top: 20px;
      .new-left{
        display: block;
        width: 113px;
        height: 90px;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        border-radius: 3px;
      }
      .new-right{
        position: absolute;
        top: 0;
        left: 128px;
        text-align: left;
        width: 216px;
        height: 90px;
        .new-right-head{
          font-size: 15px;
          font-family:PingFangSC-Medium,PingFang SC;
          font-weight:500;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .new-right-content{
          font-size:12px;
          font-weight:400;
          color:rgba(56,70,86,1);
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          margin-top: 5px;
        }
        .new-right-bottom{
          font-size:12px;
          font-weight:400;
          color:rgba(151,163,180,1);
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
  .news-container-more{
    font-size:15px;
    font-weight:500;
    color:rgba(0,198,192,1);
    text-align: center;
    margin-top: 25px;
  }
}
</style>
