<template>
  <div class="scen-container">
    <span class="title">应用场景</span>
    <van-collapse v-model="activeName" class="collapse" accordion>
      <!-- :title="item.title" :icon="item.img" -->
      <van-collapse-item v-for="(item, index) in items" :key="index" class="collapse-item" :name="index + 1" :border="false">
        <template #title>
          <div class="sn-title">
            <img :src="item.img">
            <span>{{ item.title }}</span>
          </div>
        </template>
        {{ item.content }}
      </van-collapse-item>
    </van-collapse>
  </div>
</template>
<script>
import financial from '@/assets/home/sn-financial.png'
import logistics from '@/assets/home/sn-logistics.png'
import agricul from '@/assets/home/sn-agricul.png'
import medical from '@/assets/home/sn-medical.png'
import building from '@/assets/home/sn-building.png'
import other from '@/assets/home/sn-other.png'
export default {
  data() {
    return {
      activeName: '1',
      items: [
        { img: financial, title: '金融机构', content: '助力商业银行盘活存量大型客户授信，丰富合作产品，以供应链为依托实现中小企业批量获客' },
        { img: logistics, title: '物流行业', content: '物流运费保理融资，解决中小物流企业资金压力' },
        { img: agricul, title: '农业', content: '围绕数字化农业供应链，链接金融服务资源，实现惠农、便农' },
        { img: medical, title: '医药行业', content: '以医药生产端（药厂、医药器械厂）、消费端（医院）为中心，解决医药产业链上中小企业融资问题' },
        { img: building, title: '建筑行业', content: '解决应收账款流转与融资问题，加速行业资金周转率电商：以平台交易数据为依托，解决电商平台商户融资需求' },
        { img: other, title: '其他', content: '其他更广阔的领域应用' }
      ]
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.scen-container{
  width: 100%;
  .title {
    display: block;
    width: 100%;
    margin-top: 20px;
    // height: 50px;
    font-size: 23px;
    color: #2E3A48;
    font-family: PingFangSC-Light,PingFang SC;
    font-weight: 300;
  }
}
.collapse {
  margin: 20px 20px;
}
.collapse-item {
  text-align: left;
  /deep/ .van-cell {
    width: 100%;
    height: 60px;
    margin-top: 20px;
    // border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 12px 0px rgba(5, 116, 255, 0.3);
  }
  .sn-title {
    position: relative;
    img {
      position: absolute;
      width: 32px;
      height: 32px;
      top: 3px;
    }
    span {
      position: absolute;
      left: 46px;
      top: 14px;
      color: #2E3A48;
      font-size: 14px;
      font-weight: 700;
      height: 100%;
      line-height: 100%;
    }
  }
  /deep/ .van-collapse-item__content {
    background: url("~@/assets/home/scene-bg.png") no-repeat;
    background-position: 250px 0;
    background-size: contain;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #E6F0FF;
    color: #565B62;
    font-size: 14px;
    font-weight: 500;
    padding: 20px 30px;
  }
}
</style>
