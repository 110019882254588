import axios from 'axios'
import { Dialog } from 'vant'

export const request = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: 'application/json, text/plain, */*'
  },
  timeout: 30 * 1000
})

request.interceptors.response.use(
  response => {
    return response
  },
  error => {
    Dialog.alert({
      title: '提示',
      message: '网络异常'
    }).then(() => {
      return Promise.reject(error)
    })
  }
)
