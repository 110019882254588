<template>
  <div class="advantage-container">
    <div class="advantage-container-head">方案的优势</div>
    <div class="advantage-container-content">
      <div class="advantage-container-content-tab">
        <van-tabs v-model="activeName" @change="changeName">
          <van-tab title="平台优势" name="0" />
          <van-tab title="核心企业优势" name="1" />
          <van-tab title="供应商优势" name="2" />
          <van-tab title="资金方优势" name="3" />
        </van-tabs>
        <van-swipe ref="advantageSwiper" :show-indicators="false" @change="changeSwiper">
          <van-swipe-item>
            <div class="advantage-swiper-container">
              <div class="advantage-header">
                <img :src="img4">
              </div>
              <div class="advantage-content">
                <div class="advantage-content-line">
                  <div class="advantage-content-lineson"><div />信息对称 线上确权</div>
                  <div class="advantage-content-lineson"><div />债权可拆分 流转支付</div>
                </div>
                <div class="advantage-content-line">
                  <div class="advantage-content-lineson"><div />业务线上化，在线开户、申请、签约、放款</div>
                  <div class="advantage-content-lineson"><div />贸易真实性数据交叉验证</div>
                </div>
                <div class="advantage-content-line">
                  <div class="advantage-content-lineson"><div />在线融资贴现 T+0放款</div>
                  <div class="advantage-content-lineson"><div />支持多元化资金渠道对接</div>
                </div>
                <div class="advantage-content-line">
                  <div class="advantage-content-lineson"><div />对接账户体系 账务自动清分结算</div>
                  <div class="advantage-content-lineson"><div />采用众安区块链协议Annchain，资产可穿透，数据信息安全保障，防篡改</div>
                </div>
              </div>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="advantage-swiper-container">
              <div class="advantage-header">
                <img :src="img1">
              </div>
              <div class="advantage-content">
                <div class="advantage-content-line">
                  <div class="advantage-content-lineson"><div />管理供应链</div>
                  <div class="advantage-content-lineson"><div />降低产业链整体融资成本</div>
                </div>
                <div class="advantage-content-line">
                  <div class="advantage-content-lineson"><div />利差收益获取</div>
                  <div class="advantage-content-lineson"><div />优化企业财务报表</div>
                </div>
                <div class="advantage-content-line">
                  <div class="advantage-content-lineson"><div />集团授信统一分配额度</div>
                </div>
              </div>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="advantage-swiper-container">
              <div class="advantage-header">
                <img :src="img4">
              </div>
              <div class="advantage-content">
                <div class="advantage-content-line">
                  <div class="advantage-content-lineson"><div />盘活上游中小供应商企业应收账款资产，解决融资需求</div>
                  <div class="advantage-content-lineson"><div />解决下游经销商采购、备货资金占用压力，提供融资服务，扩大企业生产经营规模</div>
                </div>
              </div>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="advantage-swiper-container">
              <div class="advantage-header">
                <img :src="img3">
              </div>
              <div class="advantage-content">
                <div class="advantage-content-line">
                  <div class="advantage-content-lineson"><div />盘活存量大型企业客户授信，丰富合作产品，提升客户合作关系</div>
                  <div class="advantage-content-lineson"><div />批量获客，依托供应链锁定上、下游优质中小微企业客户资源</div>
                </div>
                <div class="advantage-content-line">
                  <div class="advantage-content-lineson"><div />金融服务能力辐射整条供应链，提升客户溢价能力，风控可控前提之下获取更多收益</div>
                  <div class="advantage-content-lineson"><div />线上化运营，突破地域限制，提升客户服务体验，降低业务运营边际成本</div>
                </div>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
  </div>
</template>
<script>
import img1 from '@/assets/home/advantage_01.png'
import img2 from '@/assets/home/advantage_02.png'
import img3 from '@/assets/home/advantage_03.png'
import img4 from '@/assets/home/advantage_04.png'

export default {
  data() {
    return {
      img1,
      img2,
      img3,
      img4,
      activeName: '0',
    }
  },
  methods: {
    changeName(name) {
      this.$refs.advantageSwiper.swipeTo(name * 1)
    },
    changeSwiper(val) {
      this.activeName = val + ''
    }
  }
}
</script>

<style lang="scss" scoped>
.van-swipe-item {
    // background-color: #39a9ed;
    font-size: 12px;
    line-height: 350px;
    text-align: center;
    padding: 0px 30px;
    box-sizing: border-box;
  }
  .custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 5px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.1);
  }
.advantage-container{
  width: 100%;
  height: 654px;
  background: url("~@/assets/home/advantage_bg.png") no-repeat;
  background-size: 100% 100%;
  .advantage-container-head{
    text-align: center;
    font-size:23px;
    font-weight:300;
    color:rgba(255,255,255,1);
    padding-top: 55px;
  }
  .advantage-container-content{
    .advantage-container-content-tab{
      /deep/.van-tabs{
        margin-bottom: 30px;
        padding: 0 30px;
      }
      /deep/.van-tab{
        padding: 0;
      }
      /deep/.van-tabs__nav{
        background: transparent;
      }
      /deep/.van-tab{
        font-size: 13px;
        color: #97A3B4;
      }
      /deep/.van-tab--active{
        color: #00C6C0;
      }
      /deep/.van-tabs__line{
        background-color: #00C6C0;
      }
      /deep/.van-hairline--top-bottom::after{
        border: none;
        border-bottom: 1px solid rgba(255,255,255,0.3);
      }
      .advantage-swiper-container{
        .advantage-header{
          width: 100%;
          height: 187px;
          >img{
            width: 100%;
            height: 100%;
          }
        }
        .advantage-content{
          width: 100%;
          height: 240px;
          background: #fff;
          .advantage-content-line{
            width: 100%;
            line-height: normal;
            float: left;
            >div{
              width: 50%;
              text-align: left;
              padding-left: 22px;
              box-sizing: border-box;
              font-size:12px;
              font-weight:400;
              color:rgba(56,70,86,1);
              height: 100%;
              float: left;
              margin-top: 20px;
              position: relative;
              // padding-right: 8px;
              >div{
                width:5px;
                height:5px;
                background:rgba(0,198,192,1);
                display: inline-block;
                border-radius: 50%;
                position: absolute;
                left: 12px;
                top: 6px;
              }
            }
          }
          .advantage-content-line:first-child{
            margin-top: 5px;
          }
        }
      }
    }
  }
}
</style>
