<template>
  <div class="enter-container">
    <div class="enter-container-content">
      <vue-seamless-scroll :data="cardPartsStatisticsList" class="seamless-warp" :class-option="classOption">
        <ul class="item">
          <li v-for="(item, index) in cardPartsStatisticsList" :key="index">
            <img :src="item">
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>
  </div>
</template>
<script>
import img1 from '@/assets/partner/enter_01.png' // 众安保险
import img2 from '@/assets/partner/enter_02.png' // 穗银集团
import img3 from '@/assets/partner/enter_03.png' // 上海银行
import img4 from '@/assets/partner/enter_04.png' // 通华保理

import img5 from '@/assets/partner/enter_05.png' // 海南国际能源交易中心
import img6 from '@/assets/partner/enter_06.png' // 隆平高科
import img7 from '@/assets/partner/enter_07.png' // 俸发
import img8 from '@/assets/partner/enter_08.png' // 悦达集团
import img9 from '@/assets/partner/enter_09.png' // 大象
import img10 from '@/assets/partner/enter_10.png' // 永安保险
import img11 from '@/assets/partner/enter_11.png' // 中国大地保险
import vueSeamlessScroll from 'vue-seamless-scroll'

export default {
  components: {
    vueSeamlessScroll
  },
  data() {
    return {
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      img7,
      img8,
      img9,
      img10,
      img11,
      cardPartsStatisticsList: [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11]
    }
  },
  computed: {
    classOption () {
      return {
        step: 2, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 5000000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 60px;
  text-align: center;
  width: 120px !important;
  >img{
    // width: 80px;
    height: 36px;
  }
}
.enter-container{
  width: 100%;
  height: 60px;
  box-shadow: 0 0 30px rgba(5, 116, 255, 0.39);
  overflow: hidden;
  .enter-container-content{
    .seamless-warp {
      // margin-left: 80px;
      width: 100%;
      margin-top: 10px;
      // width: 1100px;
      height: 40px;
      line-height: 40px;
      display: inline-block;
      overflow: hidden;
      ul.item {
        width: 1700px;
        height: 40px;
        img {
          height: 40px;;
        }
        li {
          float: left;
          // margin-right: 10px;
          // width: 200px;
          height: 40px;
        }
      }
    }
  }
}
</style>
