<template>
  <div>
    <div class="header">
      <span class="title">关于我们</span>
    </div>
    <span class="content-title">公司介绍</span>
    <div class="content">
      <!-- <p>国内首家互联网保险公司众安保险（HK:6060）旗下金融科技的新布局——“区块链+供应链金融”综合解决方案服务商。借助众安科技自主研发区块链等前沿科技，提升在线智能化供应链金融解决方案。</p> -->
<!--      <p>众企安链隶属于上海聚啊信息技术有限公司，上海聚啊信息技术有限公司是国内首家互联网保险公司众安保险（HK:6060）旗下金融科技的新布局——“区块链+供应链金融”综合解决方案服务商。借助众安科技自主研发区块链等前沿科技，提供在线智能化供应链金融解决方案。</p>-->
      <p>我们致力于探索供应链业务场景、前沿技术和金融资源的深度融合与创新应用，通过将区块链、人工智能、大数据等技术和产业供应链场景相结合，打造“金融+场景+技术”的跨界融合供应链金融服务解决方案，提供四大板块服务产融资源整合、业务方案咨询、金融科技输出、联合运营支持，赋能产业链核心企业。</p>
<!--      <p>基于供应链上真实贸易业务场景，助力核心企业优化供应链管理，帮助资金方准确识别风险，为供应链上的小微企业解决融资难题。</p>-->
      <p>众企安链已先后服务了50余家产业龙头企业，包含数家世界500强企业，根据不同行业以及不同场景为合作伙伴对接相应银行资源，协助其构建自有供应链金融服务平台，落地产融结合战略，解决产业链上下游中小微企业融资难题。</p>
      <p>众企安链总部位于上海，旗下分、子公司及业务团队立足北京、深圳、成都、济南、昆明等地，辐射京津冀、大湾区、川渝、云贵、苏浙皖等区域，为全国各地的客户提供优质属地化供应链金融服务。</p>
    </div>
    <div class="contact-method">
      <span class="method-title">联系方式</span>
      <img class="location" src="@/assets/contact/location.png">
      <div class="place-info">
        <p class="title-f">业务咨询与市场合作</p>
        <div class="bg-f" />
        <p class="phone">021-6027 2444</p>
        <!-- <p class="title-s">公司地址</p>
        <div class="bg-s" />
        <p class="place">上海市黄浦区圆明园路209号真光大楼8楼</p> -->
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  background: url("~@/assets/about/top-bg.png") no-repeat;
  background-size: 100% 160px;
  width: 100%;
  height: 160px;
  .title {
    position: absolute;
    top: 75px;
    left: 0;
    width: 100%;
    font-size: 23px;
    color: white;
    font-weight: bold;
  }
}
.content-title {
  display: block;
  width: 100%;
  margin-top: 30px;
  font-size: 23px;
  font-family:PingFangSC-Light,PingFang SC;
  color: #2E3A48;
}
.content {
  margin: 20px 0;
  text-align: left;
  border-radius: 5px;
  background-color: #EDF4FF;
  margin-left: 13px;
  margin-right: 13px;
  font-size: 14px; /** no */
  color: #5E738A;
  padding: 15px 21px;
}
.contact-method {
  position: relative;
  text-align: center;
  height: 400px;
  .method-title {
    font-size: 23px; /** no */
    color: #2E3A48;
  }
  .location {
    display: block;
    margin-top: 6px;
    margin-left: 51.5px;
    width: 272px;
    height: 159px;
  }
  .place-info {
    position: absolute;
    // top: 50px;
    z-index: -1;
    background: url("~@/assets/contact/method-bg.png") no-repeat;
    background-size: 100% 100%;
    margin-left: 13px;
    margin-top: -85px;
    width: 349px;
    height: 243px;
    .title-f {
      position: absolute;
      top: 100px;
      left: 21px;
      color: #2E3A48;
      font-size: 15px; /** no */
      font-weight: bold;
    }
    .bg-f {
      position: absolute;
      z-index: -1;
      background-color: #00C6C0;
      height: 5px;
      width: 138px;
      top: 130px;
      left: 21px;
    }
    .phone {
      position: absolute;
      top: 126px;
      left: 21px;
      font-size: 13px; /** no */
      color: #5E738A;
    }
    .title-s {
      position: absolute;
      top: 159px;
      left: 21px;
      color: #2E3A48;
      font-size: 15px; /** no */
      font-weight: bold;
    }
    .bg-s {
      position: absolute;
      z-index: -1;
      background-color: #00C6C0;
      height: 5px;
      width: 64px;
      top: 189px;
      left: 21px;
    }
    .place {
      position: absolute;
      top: 185px;
      left: 21px;
      font-size: 13px; /** no */
      color: #5E738A;
    }
  }
}

</style>
