<template>
  <div>
    <span>这是新闻页</span>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>

</style>
