export default {
  data() {
    return {
      totalList: [
        { img: 'news_10.jpg', src: 'https://mp.weixin.qq.com/s/gnyHJpWsID4vPEM6bbpEvA', time: '2020-06-14', title: '众企安链助力合作伙伴 — 隆平数科', content: '作为隆平链（隆平数科）、隆平数科供应链金融的深度合作伙伴，众企安链管理层于6月3日、4日拜访隆平数科进行了现场座谈，对当前的合作情况及后续的深入合作进行了交流。 以下内容转载自隆平金融，作者 隆平数科，已获转载授权。', source: '众企安链' },
        { img: 'news_9.jpg', src: 'https://mp.weixin.qq.com/s/ZIXgBRueeM2rdg-QqPDj2A', time: '2020-06-10', title: '一图看懂众企安链', content: '众企安链是国内首家互联网保险公司众安保险（06060.HK）旗下金融科技的新布局-“区块链+供应链金融”综合解决方案服务商。', source: '众企安链' },

        { img: 'news_8.jpg', src: 'https://bm.cnfic.com.cn/share/index.html?from=singlemessage&isappinstalled=0#/share/articleDetail/1810659/1', time: '2020-04-08', title: '【财经分析】科技赋能疫情下金融服务 助力供应链复工复产', content: '近期的一系列扶持政策，多次提及以科技手段提升产业链在线金融服务水平，为企业提供线上化方便快捷的供应链融资服务。站位“在线新经济”，供应链金融大有可为。', source: '众企安链' },
        { img: 'news_7.jpg', src: 'https://mp.weixin.qq.com/s/-wSBsN1iOJPU1EHg7HO_Og', time: '2020-03-16', title: '众安科技与多家单位联合发布中国“区块链+供应链金融”行业研究报告', content: '疫情冲击下，很多企业出现资金紧张局面，如何拓宽企业融资渠道，成为了一大社会焦点。金融是经济的血脉。如何在防范金融风险的基础上，让企业贷得到钱，成为了政府与金融监管部门的当前要务。', source: '众企安链' },
        { img: 'news_6.jpg', src: 'https://mp.weixin.qq.com/s/Xj9j6m85Xq3zZwd_ossVtw', time: '2020-03-11', title: '【直播干货】如何构建在线化的供应链金融服务平台', content: '传统线下供应链金融体系手续繁琐，多方沟通协作效率低下导致资金周转效率低，企业经营成本高。信息的不对称性也加大了中小企业的融资的难度和资金方的风险管理难度。近年来，以大数据、区块链、人工智能、云计算为代表的金融科技在经历了数年的探索与沉淀之后，进一步促进了金融行业的创新和产业的融合，从而逐渐诞生一种新的金融服务模式——线上供应链金融。', source: '众企安链' },
        { img: 'news_5.jpg', src: 'https://mp.weixin.qq.com/s/cp6gcMlYj2pepOWnW5N9bQ', time: '2020-03-06', title: '一图读懂众安科技', content: '众企安链是众安保险旗下的全资子公司，专注于区块链、人工智能、大数据、云计算等前沿技术，聚焦于保险科技在国内外市场的技术输出，助力保险生态客户快速高质发展，实现保险普惠。', source: '众企安链' },
        { img: 'news_4.jpg', src: 'https://mp.weixin.qq.com/s/dlrGaIBQB0H9Tr2cPIB2ww', time: '2020-02-26', title: '【直播干货】疫情当前，商业保理公司如何携中小企业共渡难关', content: '岁末年初，猝不及防的新冠肺炎疫情突然爆发。受疫情影响，大量中小微企业面临现金流紧张、营收下降、成本提高、风险加大、运营压力增加等问题。国家工信部印发的《关于应对新型冠状病毒肺炎疫情帮助中小企业复工复产共渡难关有关工作的通知》，明确疫情期间积极运用供应链金融、商业保理等融资方式帮助中小企业融资，全力保障企业有序复工复产。作为商业保理公司，如何帮助中小企业提升资金周转效率、缓解经营压力。', source: '众企安链' },

        { img: 'news_3.jpg', src: 'https://mp.weixin.qq.com/s/4hIb52S6eUrtLW2N_OAXXw', time: '2019-12-20', title: '众安开放日来咯！众企安链与您共建安全可信的产业金融生态圈', content: '众安保险CEO姜兴发表了“新保险 ‘精’时代”为主题的演讲。他提到：“今年以来，我们尤为关注以新场景为触点，精耕保险存量市场，激活线上购买力。一边是中国巨大的保险市场；另一边是不断涌现的新触点、新场景、新玩法。他回顾了2019年众安所取得的成绩，如进一步打造健康生态闭环、与互联网场景深度融合、科技对外输出……众安以用户为中心，以数据智能为核心，赋能保险价值链。新保险时代是开放、融合、共创的时代。未来，众安将继续与伙伴们共创保险新生态，最大化释放生态协同的价值。', source: '众企安链' },
        { img: 'news_2.jpg', src: 'https://mp.weixin.qq.com/s/W0oeBlgAz3W98TKsszX_Xg', time: '2019-11-13', title: '众安六岁啦！科技一路温暖同行！', content: '11月6日，众安迎来了六周岁的生日。六是一个喜悦的数字，自带顺遂的光环，传递希望的力量。', source: '众企安链' },
        { img: 'news_1.jpg', src: 'https://mp.weixin.qq.com/s/s1m37gPZ4MiQDHqo1A-vDA', time: '2019-11-05', title: '“双链合一”众安科技用区块链构建安全可信的供应链金融生态', content: '10月24日,中共中央政治局就区块链技术发展现状和趋势进行第十八次集体学习。强调要发挥区块链在促进数据共享、优化业务流程、降低运营成本、提升协同效率、建设可信体系等方面的作用。要推动区块链和实体经济深度融合,解决中小企业贷款融资难、银行风控难、部门监管难等问题。', source: '众企安链' },
      ]
    }
  },
  created() {
    const query = this.$route.query
    for (var key in this.ruleForm) {
      if (query[key]) {
        this.ruleForm[key] = query[key]
      }
    }
  },
  methods: {
    clickMethod(src) {
      this.linkUrl = src
      var a = document.getElementById('linkId')
      a.setAttribute('href', this.linkUrl)
      a.click()
    }
  }
}
