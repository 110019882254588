<template>
  <div class="position-container">
    <div class="position-container-head">产品定位</div>
    <div class="position-container-content">
      <div class="position-container-content-block position-container-content-one">
        <div class="position-container-content-title">定位</div>
        <div class="position-container-content-content">区块链+供应链金融的综合解决方案服务商</div>
        <div class="position-container-content-content">我们不做平台 我们与客户共建安全可信的产业金融生态圈</div>
      </div>
      <div class="position-container-content-block position-container-content-one">
        <div class="position-container-content-title">业务模式</div>
        <div class="position-container-content-content">业务解决方案咨询+供应链金融平台技术输出+业务联合运营</div>
      </div>
      <div class="position-container-content-block position-container-content-two">
        <div class="position-container-content-title">产品介绍</div>
        <div class="position-container-content-content">e信证——以供应链核心企业为中心，核心企业对上游供应商应付账款为基础，采用反向保理交易结构，由核心企业在线开立，在供应链体系内封闭流转，可拆分、可转让、可融资的电子债权凭证。</div>
        <div class="position-container-content-content">e信融——围绕供应链核心企业下游，以核心企业及其经销商之间真实贸易往来为基础，基于“进销存”数据和对货品的动态管理，依具体供应链场景构建的中小微企业创新融资产品方案。</div>
      </div>
    </div>
    <div class="position-container-bottom">
      <div class="position-container-bottom-one">
        <div class="bottom-title">上游供应商-“e信证”</div>
        <div class="bottom-content">· 创新商业信用支付工具</div>
        <div class="bottom-content">· “应收账款债权电子凭证”</div>
        <img class="bottom-img" :src="img1" alt="">
      </div>
      <div class="position-container-bottom-two">
        <div class="bottom-title">下游分销商-“e信融”</div>
        <div class="bottom-content-spec">基于区块链技术的小微企业信贷线上解决方案</div>
        <div class="bottom-content">· 数据贷</div>
        <div class="bottom-content">· 存货质押</div>
        <div class="bottom-content">· 预付款+仓单质押融资</div>
        <img class="bottom-img" :src="img2" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import img1 from '@/assets/home/index_positon_onel.png'
import img2 from '@/assets/home/index_positon_twol.png'

export default {
  data() {
    return {
      img1,
      img2,
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.position-container{
  width: 100%;
  // height: 1013px;
  height: 1050px;
  .position-container-head{
    font-size:23px;
    font-weight:300;
    color:rgba(46,58,72,1);
    margin-top: 25px;
    text-align: center;
  }
  .position-container-content{
    margin-top: 5px;
    padding: 0 13px;
    text-align: left;
    .position-container-content-block{
      width: 100%;
      background: #EDF4FF;
      margin-top: 15px;
      padding: 0 21px;
      box-sizing: border-box;
      .position-container-content-title{
        margin-top: 20px;
        font-size:14px;
        font-weight:600;
        color:rgba(46,58,72,1);
        display: inline-block;
        border-bottom: 5px solid #00C6C0;
        line-height: 6px;
      }
      .position-container-content-content{
        font-size:14px;
        font-weight:400;
        color:rgba(94,115,138,1);
        margin-top: 6px;
        // margin-bottom: 15px;
        line-height: 22px;
      }
    }
    // .position-container-content-one{
    //   // height: 101px;
    //   min-height: 10px;
    // }
    // .position-container-content-two{
    //   // height: 282px;
    //   min-height: 282px;
    // }
  }
  .position-container-bottom{
    position: relative;
    width: 100%;
    height: 440px;
    .position-container-bottom-one{
      position: absolute;
      background: url("~@/assets/home/index_positon_one.png") no-repeat;
      background-size: 100% 100%;
      top: 25px;
      left: 13px;
      width: 220px;
      height: 196px;
      .bottom-title{
        font-size:16px;
        font-weight:600;
        color:rgba(255,255,255,1);
        margin-top: 30px;
      }
      .bottom-content{
        font-size:13px;
        font-weight:400;
        color:rgba(255,255,255,1);
        margin-top: 7px;
        margin-left: 40px;
        text-align: left;
      }
      .bottom-img{
        width: 82px;
        height: 82px;
        margin-left: 60px;
        margin-top: 8px;
      }
    }
    .position-container-bottom-two{
      position: absolute;
      bottom: 15px;
      background: url("~@/assets/home/index_positon_two.png") no-repeat;
      background-size: 100% 100%;
      right: 0;
      width: 215px;
      height: 241px;
      .bottom-title{
        font-size:16px;
        font-weight:600;
        color:rgba(255,255,255,1);
        margin-top: 40px;
        margin-left: 50px;
      }
      .bottom-content-spec {
        font-size:13px;
        font-weight:400;
        color:white;
        margin-top: 2px;
        margin-left: 30px;
        text-align: left;
      }
      .bottom-content{
        font-size:13px;
        font-weight:400;
        color:rgba(255,255,255,1);
        margin-top: 5px;
        margin-left: 40px;
        text-align: left;
        margin-left: 60px;
      }
      .bottom-img{
        width: 82px;
        height: 82px;
        margin-top: -5px;
        margin-left: 20px;
      }
    }
  }
}
</style>
