import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index'
import About from '../views/about/index'
import Dynamic from '../views/dynamic/index'
import News from '../views/news/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    // meta: { title: '首页' },
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // meta: { title: '关于' },
    component: About
  },
  {
    path: '/dynamic',
    name: 'Dynamic',
    // meta: { title: '动态' },
    component: Dynamic
  },
  {
    path: '/news',
    name: 'News',
    // meta: { title: '新闻' },
    component: News
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

export default router
