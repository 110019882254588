<template>
  <div class="contact-container">
    <div class="title">联系我们</div>
    <div class="method-first">请留下您的联系方式和问题，我们解决方案</div>
    <div class="method-last">专家将尽快联系您</div>

    <div class="cell-container">
      <van-form ref="submitForm" label-align="left" @submit="onSubmit">
        <van-field v-model="name" label="您的称呼" placeholder="请输入称呼" />
        <van-field v-model="phone" label="您的电话号码" placeholder="请输入您的电话号码" />
        <van-field v-model="company" name="公司" label="公司" placeholder="请输入您的公司" />
        <van-field v-model="email" label="邮箱" placeholder="请输入您的邮箱" />
        <van-field v-model="remark" class="remark" label="备注" placeholder="请输入您想咨询的内容" type="textarea" rows="4" maxlength="200" show-word-limit />

        <van-button class="submit-btn" round block type="info" native-type="submit">
          提 交
        </van-button>

      </van-form>

    </div>
  </div>
</template>
<script>
import { contactSubmit } from '@/api/common'
import { Dialog } from 'vant'

export default {
  data() {
    return {
      name: '',
      phone: '',
      company: '',
      email: '',
      remark: ''
    }
  },
  methods: {
    checkData() {
      return new Promise((resolve, reject) => {
        if (!this.name) {
          reject('请输入您的称呼')
        }
        if (!this.phone) {
          reject('请输入您的电话号码')
        }
        if (!this.company) {
          reject('请输入您的公司')
        }
        if (!this.email) {
          reject('请输入您的邮箱')
        }
        resolve()
      })
    },
    onSubmit() {
      this.checkData().then(() => {
        this.$toast.loading({
          mask: true,
          message: '正在提交信息',
          duration: 0
        })
        contactSubmit({
          name: this.name,
          phone: this.phone,
          company: this.company,
          email: this.email,
          remark: this.remark
        }).then(({ data }) => {
          this.$toast.clear()
          if (data.resultCode === '000000') {
            // 成功
            Dialog.alert({
              title: '提示',
              message: '您的信息已提交成功'
            }).then(() => {
              this.resetForm()
            })
          } else {
            this.$toast(data.msg || '信息提交失败')
          }
        }).catch(() => {
          this.$toast.clear()
          this.$toast('信息提交失败')
        })
      }).catch(message => {
        this.$notify(message)
      })
    },
    resetForm() {
      this.name = ''
      this.phone = ''
      this.company = ''
      this.email = ''
      this.remark = ''
      this.$refs.submitForm.resetValidation()
      // this.$refs.submitForm.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-container {
  background: url("~@/assets/home/contact-bg.png") no-repeat;
  background-size: 100% 635px;
  width: 100%;
  height: 635px;
}
.title {
  color: white;
  font-size: 23px; /** no */
  padding-top: 40px;
}
.method-first {
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px; /** no */
  margin-top: 10px;
}
.method-last {
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px; /** no */
}
.cell-container {
  margin-top: 35px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: transparent;
  /deep/ .van-cell {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    &:not(:nth-child(5)) {
      border-bottom: rgba(255, 255, 255, 0.9) solid 0.5px;
    }
    &::after {
      display: none;
    }
  }
  /deep/ .van-cell__title {
    text-align: left;
    color: white;
    font-weight: 500;
    font-size: 13px;
  }
  /deep/ .van-field__control {
    color: white;
  }
  /deep/ .van-field__word-limit {
    color: white;
    padding-right: 2px;
  }
  /deep/ .van-field__control::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  .remark {
    /deep/ .van-field__value {
      border-radius: 3px;
      border: rgba(255, 255, 255, 0.4) solid 1px;
    }
  }
  .submit-btn {
    margin-top: 35px;
    width: 100%;
    height: 45px;
    color: white;
    font-size: 17px;
    font-weight: 500;
  }
}
</style>
